import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/administrator.scss"

const AdministratorPage = () => {
  const imageAravind = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "aravind.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Aravind - The Administrator"
        description="Aravind Karavattu, Horary Astrologer and Ashtamangalaprasnam Daivajna based in Cochin is the Administrator."
        keyword="ashtamangalaprasnam, devaprasnam, astrologer, aravind, aravind karavattu, horary, daivajna, cochin, ernakulam, kerala"
      />
      <section className="admin">
        <div className="admin__image-wrap">
          <Image
            className="admin__image"
            fluid={imageAravind.file.childImageSharp.fluid}
            alt="Aravind Karavattu"
          />
        </div>
        <div className="admin__content-wrap">
          <h1 className="h1_decorative admin__title">Aravind Karavattu</h1>
          <h2 className="h4 admin__subtitle">
            The Administrator, Horary Astrologer
          </h2>
          <p>
            Aravind Karavattu hails from a reputed family of astrologers and he
            represents the fourth generation. Aravind was intrigued by the
            influence of the solar system in our lives and the legends about his
            ancestors, and he set out on the quest of finding answers at the age
            of 16 by joining Kerala Jyotisha Parishath for Jyotisha Visharad
            course under Chethalur Krishnankutty Gupthan and later learned
            'Varaha Hora' under Kootanadu Ravunni Panicker. He learned ‘Prashna
            Margam' under Padumana Balakrishna Panicker. He also had been the
            disciple of revered individuals, Late Mannar Krishnapilla and
            Radhakrishnan Nair Edappally.
          </p>
          <p>
            Aravind learned Devaprashnam and Ashtamangalaprasnam from the
            legends - Kaimukku Raman Akkithirippadu and Krishnankutty Gupthan
            and has acted as the moderator of various prasnas along with them
            and other eminient Daivajnas.
          </p>
          <div className="admin__info">
            <dl>
              <dt className="h5">Qualifications</dt>
              <dd>Jyothisha Bhooshanam (1999),</dd>
              <dd>Jyothisha Acharaya (2000),</dd>
              <dd>Jyothisha Kesari (2000),</dd>
              <dd>Prashna Bhooshanam (2001)</dd>
              <dd>Jyothisha Ratna (2002),</dd>
              <dd>Prashna Visharth (2004),</dd>
              <dd>Jyotisha Visharad (2010)</dd>
            </dl>
            <dl>
              <dt className="h5">Awards and Accolades</dt>
              <dd>
                Jyotisha Bhooshanam Award from Kerala state educational minister
                – Mr. Abdu Rabb in the year 2013.
              </dd>
            </dl>
          </div>
          <h4 className="admin__info-title">Get in touch with Aravind</h4>
          <div className="admin__info">
            <dl>
              <dt className="h5">You can call Aravind at</dt>
              <dd>
                <a className="link_text" href="tel:+919567752287">
                  +919567752287
                </a>
              </dd>
              <dd>
                <a className="link_text" href="tel:+919496092120">
                  +919496092120
                </a>
              </dd>
            </dl>
            <dl>
              <dt className="h5">Or mail him at</dt>
              <dd>
                <a
                  className="link_text"
                  href="mailto:astrologeraravind@gmail.com"
                >
                  astrologeraravind@gmail.com
                </a>
              </dd>
            </dl>
            <dl>
              <dt className="h5">Consultation Centers</dt>
              <dd>
                <a
                  className="link_text"
                  href="https://goo.gl/maps/K29Mau6GbpbhUeJE6"
                >
                  Kacherippady Office
                </a>
              </dd>
              <dd>
                <a
                  className="link_text"
                  href="https://goo.gl/maps/ZyDmNRo6L8RFvvzN8"
                >
                  K R Puram, Bangalore Office
                </a>
              </dd>
            </dl>
            <dl>
              <dt>
                <a
                  title="Link to the Facebook page Astrologer Aravind"
                  href="https://www.facebook.com/aravindastrologer"
                  target="_blank"
                  rel="noreferrer"
                  className="button_facebook"
                >
                  <svg
                    viewBox="0 0 36 36"
                    className="a8c37x1j ms05siws hwsy1cff b7h9ocf4"
                    fill="url(#jsc_s_2)"
                    height="40"
                    width="40"
                  >
                    <defs>
                      <linearGradient
                        x1="50%"
                        x2="50%"
                        y1="97.0782153%"
                        y2="0%"
                        id="jsc_s_2"
                      >
                        <stop offset="0%" stopColor="#0062E0"></stop>
                        <stop offset="100%" stopColor="#19AFFF"></stop>
                      </linearGradient>
                    </defs>
                    <path d="M15 35.8C6.5 34.3 0 26.9 0 18 0 8.1 8.1 0 18 0s18 8.1 18 18c0 8.9-6.5 16.3-15 17.8l-1-.8h-4l-1 .8z"></path>
                    <path
                      className="p361ku9c"
                      fill="#fff"
                      d="M25 23l.8-5H21v-3.5c0-1.4.5-2.5 2.7-2.5H26V7.4c-1.3-.2-2.7-.4-4-.4-4.1 0-7 2.5-7 7v4h-4.5v5H15v12.7c1 .2 2 .3 3 .3s2-.1 3-.3V23h4z"
                    ></path>
                  </svg>
                  fb.com/aravindastrologer
                </a>
              </dt>
            </dl>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AdministratorPage
